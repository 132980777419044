import { serviciosReducer } from "../reducers/serviciosReducer";
import { motivosReducer } from "../reducers/motivosReducer";
import { estatusReducer } from "../reducers/estatusReducer";
import { loadingReducer } from "../reducers/loadingReducer";
import { lastCheckReducer } from "../reducers/lastCheckReducer";

export const allReducers = {
  serviciosReducer,
  motivosReducer,
  estatusReducer,
  loadingReducer,
  lastCheckReducer,
};
