export const ver = ".05";

// QA
// export const api_key = "f594b85b-7cad-43d6-961e-56faf5b61bdc";
// const urlBase =
//   "https://api-gc-qa.service.gnp.com.mx/apsin/api/asistencia-vial/proveedor";

// //PROD
export const api_key = "e7a7edcb-5ef9-43d2-b60a-311e57d7e9a6";
const urlBase =
  "https://api-gc.service.gnp.com.mx/apsin/api/asistencia-vial/proveedor";

export const URL_GET_ESTATUS = urlBase + "/catalagos/estatus";
export const URL_GET_MOTIVOS = urlBase + "/catalogos/motivo";
export const URL_GET_SERVICIOS = urlBase + "/asistencia/servicios/estatus";
export const URL_GET_SERVICIO = urlBase + "/asistencia/servicio";
export const URL_POST_SERVICIO = urlBase + "/servicio/asignacion";
