export const types = {
  storeEstatus: "[estatusActions] storeEstatus",
  storeMotivos: "[motivosActions] storeMotivos",
  setEstatusSelected: "[estatusActions] setEstatusSelected",

  storeServicios: "[serviciosActions] storeServicios",
  storeServicio: "[serviciosActions] storeServicio",

  setLoadingPage: "[loadingActions] setLoadingPage",
  setLastCheck: "[setLastCheck] setLastCheck",
};
