import React from "react";
import Titulo from "../../components/titulo/Titulo";
import AccionBoton from "./_BotonAcciones";
import Estatus from "./_Estatus";

export default function _Acciones() {
  return (
    <div>
      <Estatus />
      <AccionBoton />
    </div>
  );
}
