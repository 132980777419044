import Filtro from "./_Filtro";
import Tabla from "./_Tabla";

export default function Servicios() {
  return (
    <div>
      <Filtro />
      <Tabla />
    </div>
  );
}
